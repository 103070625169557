<template>
  <FormModal
    v-if="data"
    title="New Site Transformation"
    :endpoint="`partners/${partner_id}/sites/${site_id}/transformations`"
    :data="data"
    v-bind="$attrs"
    @success="success"
  >
    <InputGroup label="Name" name="name" description="Example: Tour page CTA">
      <TextInput v-model="data.name" />
    </InputGroup>

    <InputGroup label="Language" name="language_id">
      <Select v-model="data.language_id" endpoint="options/languages" prepend-option="All Languages" />
    </InputGroup>

    <InputGroup
      label="Selector(s)"
      name="selector"
      description="Press 'enter' after each selector. Examples: .selector, #selector, div[attribute], div:has(.selector)"
    >
      <TagsInput v-model="data.selector" placeholder="Enter selector(s)..." :spellcheck="false" />
    </InputGroup>

    <InputGroup label="Advisor CTA" name="advisor_cta">
      <ToggleSwitch v-model="data.advisor_cta" label="Transform the element into an advisor CTA" />
    </InputGroup>

    <InputGroup
      v-if="!data.advisor_cta"
      label="Href"
      name="href"
      optional
      description="Include protocol, i.e. https:// or mailto:"
    >
      <TextInput v-model="data.href" />
    </InputGroup>

    <InputGroup
      label="Text"
      name="text"
      optional
      :description="data.advisor_cta ? 'Leave blank to use default CTA label' : ''"
    >
      <TextInput v-model="data.text" />
    </InputGroup>

    <InputGroup
      label="Internal Notes"
      name="notes"
      optional
      description="Any additional notes about this transformation."
    >
      <TextInput v-model="data.notes" textarea />
    </InputGroup>

    <InputGroup label="Enabled" name="enabled">
      <ToggleSwitch v-model="data.enabled" label="Enable this transformation" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  props: {
    partner_id: {
      type: Number,
      required: true
    },
    site_id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    data: {
      language_id: null,
      name: null,
      selector: [],
      href: null,
      text: null,
      advisor_cta: false,
      notes: null,
      enabled: true
    }
  }),
  methods: {
    success(result) {
      this.$notification.success(`Transformation successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
